import React from 'react';
import { Link } from 'gatsby';
import Form from './Form';
import { goToClassroomOrJournal } from '../helpers';

const fields = [
  {
    name: 'email',
    label: 'Почта',
  },
  {
    name: 'password',
    label: 'Пароль',
    type: 'password',
  },
];

const submitSuccess = ({ data: user }) => goToClassroomOrJournal(user);

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Обязательное поле';
  }

  if (!values.password) {
    errors.password = 'Обязательное поле';
  }

  return errors;
};

const LoginForm = () => (
  <div>
    <Form
      submitUrl="/auth/login"
      fields={fields}
      form="login"
      validate={validate}
      onSubmitSuccess={submitSuccess}
    />
    <p className="text-right">
      <Link to={'/forgot_password'}>Забыли пароль?</Link>
    </p>
  </div>
);

export default LoginForm;
