import React from 'react';
import Helmet from 'react-helmet';

import LoginForm from '../components/LoginForm';
import wrapInSection from '../components/wrapInSection';
import wrapInLayout from '../components/wrapInLayout';

const Login = () => (
  <div className="page-wrapper">
    <Helmet title="Вход – КЭСПА" />
    <div className="row">
      <div className="col-md-5">
        <h2>Войти</h2>
        <LoginForm />
      </div>
    </div>
  </div>
);

export default wrapInLayout(wrapInSection(Login));
